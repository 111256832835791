<template>
  <article class="home">
    <div class="background top">
      <div class="block">
        <div class="inner"></div>
      </div>

      <div class="block">
        <div class="inner"></div>
      </div>
    </div>

    <div class="background">
      <div class="block">
        <div class="inner"></div>
      </div>

      <div class="block">
        <div class="inner"></div>
      </div>
    </div>

    <div class="main position-absolute top-50 start-50 translate-middle">
      <div class="row align-items-center m-0 p-0">
        <div class="col-12 col-md-7 col-xl-auto">
          <div class="name d-inline-block h-100">
            <h1>Niels<span class="d-block filled">van der Vlist</span><sub class="mt-2">Frontend developer</sub></h1>
          </div>
        </div>

        <div class="col-12 col-md-5 col-xl-auto">
          <div class="its-me d-inline-block">
            <img :src="require('@/assets/niels-van-der-vlist.jpeg')" alt="Niels van der Vlist" title="Niels van der Vlist" >
          </div>
        </div>
      </div>
    </div>

    <div class="contact position-absolute bottom-0 start-0">
      <a href="mailto:mail@nielsvandervlist.nl" title="Stuur een bericht">
        <i class="fas fa-envelope"></i>
      </a>
      <a href="https://nl.linkedin.com/in/niels-van-der-vlist-203815123" target="_blank" title="Bezoek linkedin profiel">
        <i class="fab fa-linkedin"></i>
      </a>
    </div>
  </article>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
<style lang="scss" scoped>
  @keyframes opacity {
    0%,
    100% {
      opacity: 1;
    }

    80% {
      opacity: 0.2;
    }
  }

  .background {
    position: absolute;
    height: 100vh;
    overflow: hidden;
    width: 23vw;

    &:not(.top) {
      right: 0;
      bottom: 0;
    }

    &.top {
      transform: rotate(180deg);
      height: 50vh;
      left: 0;
      top: 0;
    }

    .block {
      position: absolute;
      transform: translateZ(0);
      transform-style: preserve-3d;
      bottom: 0;
      z-index: 2;
      .inner {
        background: -webkit-linear-gradient(-45deg, var(--nv-orange-color) 0%, var(--nv-body-background-color) 100%);
        padding-top: 12rem;
        width: 8rem;
        height: 30vh;
        position: relative;
        transform: rotate3d(7, -10, 1, 45deg);
        transform-origin: left;
        position: absolute;
        bottom: -15vh;
        left: 0;

        &:before {
          background: -webkit-linear-gradient(-25deg, var(--nv-orange-color) 0%, var(--nv-body-background-color) 100%);
          transform: rotate3d(-1.4, -5, 0.7,55deg);
          transform-origin: left;
          width: 90vw;
          height: 120%;
          position: absolute;
          top: -0.4rem;
          content: "";
          padding-right: 0;
          right: -90vw;
        }
      }

      + .block {
        margin-top: -1rem;
        z-index: 1;
        .inner {
          background: -webkit-linear-gradient(45deg, rgba(255,255,255,0) 0%, var(--nv-grey-color) 100%);
          height: 40vh;
          left: 85px;
          &:before {
            background: -webkit-linear-gradient(-25deg, var(--nv-grey-color) 0%,rgba(255,255,255,0) 100%);
            transform: rotate3d(-1.4, -5, 0.7,55deg);
            width: 125vw;
            right: -125vw;
            top: -0.45rem;
          }
        }
      }
    }
  }

  article {
    height: 100vh;
    background: var(--nv-lightest-color);

    .main {
      width: max-content;
      z-index: 10;
    }

    .its-me {
      margin: 0 4rem;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 80%;
        height: 80%;
        bottom: -3px;
        right: -3px;
        box-shadow: 2px 2px 0px 0px var(--nv-orange-color)
      }

      &:after {
        content: "";
        position: absolute;
        width: 60%;
        height: 60%;
        bottom: -8px;
        right: -8px;
        box-shadow: 2px 2px 0px 0px var(--nv-orange-color)
      }
    }
  }

  .name {
    text-align: center;
    display: inline-block;
    h1 {
      font-family: 'Luckiest Guy', cursive;
      color: var(--nv-orange-color);
      font-size: 2.5rem;

      .filled {
        background: var(--nv-orange-color);
        color: var(--nv-lightest-color);
        font-size: 1.8rem;
        padding: 15px 20px 5px;
        margin: -5px 0 23px;
      }

      sub {
        display: block;
        color: var(--nv-orange-color);
      }
    }
  }

  .contact {
    --size: 50px;

    padding: 1rem;

    a {
      display: block;
      text-align: center;
      line-height: var(--size);
      height: var(--size);
      width: var(--size);
      margin-top: 1rem;
      background: var(--nv-orange-color);
      border-radius: 100%;
      box-shadow: 1px 1px 3px var(--nv-grey-color);
      &,
      * {
        color: var(--nv-lightest-color);
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    .main {
      width: 80vw !important;
    }
  }

  @media (max-width: 768px) {
    .background {
      width: 53vw;
    }

    .contact {
      top: 0 !important;
      right: 0 !important;
      left: auto !important;
      bottom: auto;

      a {
        display: inline-block;
        margin-left: 1rem;
      }
    }
    .main {
      width: 100vw !important;
      text-align: center;
      .name {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;

          .filled {
            font-size: 2rem;
          }
        }
      }

      img {
        max-width: 60vw;
      }
    }
  }
</style>
