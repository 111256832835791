<template>
  <router-view/>
</template>

<style lang="scss">
body,
:before,
:after {
  // Colors

  --nv-body-background-color: #EAE7DC;
  --nv-lightest-color: #EAE7DC;
  --nv-sand-color: #D8C3A5;
  --nv-grey-color: #8E8D8A;
  --nv-light-orange-color: #302eb4;
  --nv-orange-color: #5f86b6;
}
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--nv-sand-color);
  background: var(--nv-body-background-color);
  padding: 0;
  margin: 0;
  height: 100%;
}

</style>
